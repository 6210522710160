import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    key: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    key: "material_number",
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
    key: "batch_number",
  },
  {
    title: T("当前库存"),
    dataIndex: "inventory_quantity",
    key: "inventory_quantity",
  },
  {
    title: T("调拨数量"),
    key: "transfer_quantity",
    dataIndex: "transfer_quantity",
    scopedSlots: { customRender: "transfer_quantity" },
  },
  {
    title: T("单位"),
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
