import { T } from "@/locales";

export const rules = {
  number: [
    { required: true, message: T('请输入单据编号'), trigger: 'change' },
    { max: 32, message: T('超出最大长度 (32)'), trigger: 'change' },
  ],
  out_warehouse: [
    { required: true, message: T('请选择出库仓库'), trigger: 'change' },
  ],
  in_warehouse: [
    { required: true, message: T('请选择入库仓库'), trigger: 'change' },
  ],
  client: [
    { required: true, message: T('请选择货主'), trigger: 'change' },
  ],
  remark: [
    { max: 256, message: T('超出最大长度 (256)'), trigger: 'change' },
  ],
}